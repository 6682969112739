import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/pt-br";
import './SchedulerCustom.scss'

import { Button, ButtonGroup } from "react-bootstrap";
import { Calendar, EventPropGetter, Views, momentLocalizer } from "react-big-calendar";
import { displayName, formatDateInEN, formatDateToYYYYMMDD } from "../../../../commons/util/HallAppUtil";
import { useEffect, useState } from "react";

import CompanyAPI from "../../../company/CompanyAPI";
import FloatingButton from "../../../../commons/buttons/FloatingButton";
import HallAppShedulerWeek from "./HallAppShedulerWeek";
import ModalScheduling from "./ModalScheduling";
import SchedulingAPI from '../../SchedulingAPI';
import Select from 'react-select'
import UserService from "../../../../auth/UserService";
import dayjs from "dayjs";
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

const localizer = momentLocalizer(moment);

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: 60,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

var defaultMessages = {
    date: "Data",
    time: "Hora",
    event: "Evento",
    allDay: "Dia Todo",
    week: "Semana",
    work_week: "Eventos",
    day: "Dia",
    month: "Mês",
    previous: "Anterior",
    next: "Próximo",
    yesterday: "Ontem",
    tomorrow: "Amanhã",
    today: "Hoje",
    agenda: "Agenda",
    noEventsInRange: "Não há eventos no período.",
    showMore: function showMore(total) {
        return "+" + total + " mais";
    }
};

const eventPropGetter = (event) => {
    const colors = ["#ff7f50", "#6495ed", "#ff69b4", "#008000", "#ff4500"];
    const index = event.id % colors.length; // Distribui cores dinamicamente
  
    return {
      style: {
        backgroundColor: colors[index],
        color: "#fff", // Mantém o texto legível
        borderRadius: "5px",
        padding: "5px",
        border: `1px solid ${colors[index]}`,
      },
    };
  };

function currentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

function SchedulerCustom() {
    const [viewType, setViewType] = useState(Views.DAY);
    const [showModalScheduling, setShowModalScheduling] = useState(false)
    const [eventSelected, setEventSelected] = useState(null);
    const [userAdmin, setUserAdmin] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employeeSelected, setEmployeeSelected] = useState(null);

    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState({
        startDate: currentDate(),
        endDate: null,
        viewType: viewType
    });

    const schedulingAPI = new SchedulingAPI();
    const userService = new UserService();
    const companyAPI = new CompanyAPI();

    const advancedSearch = async () => {
        try {
            const resp = await schedulingAPI.advancedSearch(filter);
            if (resp.data) {
                const eventsMap = resp.data.map((e) => {
                    const [year, month, day] = e.schedulingDate.split("-").map(Number);
                    const [startHour, startMinute] = e.startTime.split(":").map(Number);
                    const [endHour, endMinute] = e.endTime.split(":").map(Number);
                    return {
                        ...e,
                        title: e.customerName,
                        start: new Date(year, month - 1, day, startHour, startMinute, 0, 0),
                        end: new Date(year, month - 1, day, endHour, endMinute, 0, 0),
                        description: e.serviceName,
                    }
                })
                setEvents(eventsMap)
            } else {
                setEvents([])
            }
        } catch (e) {
            console.error("error serach schedulings ", e)
            setEvents([])
        }
    };

    useEffect(() => {
        advancedSearch();
    }, [filter]);

    useEffect(() => {
        const isAdmin = isUserAdmin()
        if (isAdmin) {
            getAllEmployee();
        }
        setUserAdmin(isAdmin)
    }, []);

    function isUserAdmin() {
        const user = userService.getUserData();
        return user.rules?.length !== 0 && user.rules?.includes("COMPANY");
    }

    function changeType(newType) {
        setViewType(newType)
        handleNavigate(new Date(), newType)
    }

    const handleNavigate = (date, view) => {
        if (view === 'day') {
            setFilter({
                startDate: formatDateInEN(date),
                endDate: null,
                viewType: view,
                employeeId: userAdmin ? employeeSelected?.value : null
            })
        } else if (view === 'month') {
            const dt = getMonthRange(formatDateToYYYYMMDD(date));
            setFilter({
                startDate: dt.startDate,
                endDate: dt.endDate,
                viewType: view,
                employeeId: userAdmin ? employeeSelected?.value : null
            })
        } else if (view === 'week') {
            const startDate = formatDateInEN(date);
            const endDate = addSevenDays(startDate);
            setFilter({
                startDate: startDate,
                endDate: endDate,
                viewType: view,
                employeeId: userAdmin ? employeeSelected?.value : null
            })
        }
    };

    function addSevenDays(dateString) {
        const date = new Date(dateString); // Converte a string para uma data
        date.setDate(date.getDate() + 7); // Soma 7 dias
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ajusta o mês para formato MM
        const day = String(date.getDate()).padStart(2, '0'); // Ajusta o dia para formato DD
        return `${year}-${month}-${day}`; // Retorna no formato YYYY-MM-DD
    }

    const getMonthRange = (dateString) => {
        // Criar um objeto de data a partir da string
        const date = dayjs(dateString).toDate();

        // Obter o primeiro dia do mês
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

        // Obter o último dia do mês
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        // Formatar as datas como YYYY-MM-DD
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        };

        return {
            startDate: formatDate(firstDay),
            endDate: formatDate(lastDay),
        };
    };

    const handleEventClick = (event) => {
        setEventSelected(event);
        setShowModalScheduling(true);
    };

    const getAllEmployee = async () => {
        try {
            const response = await companyAPI.getAllEmployee(companyAPI.getCompanyManagment().id);
            const options = response.data.map(d => ({ value: d.id, label: displayName(d.name) || d.email }));
            setEmployeeSelected(options.find((e) => e.value === userService.getUserData().id));
            setEmployees(options)
        } catch (_) {
            setEmployees([])
        }
    };

    const changeSelectedEmployee = (employee) => {
        setEmployeeSelected(employee);
        setFilter({
            ...filter,
            employeeId: employee.value
        })
    };

    return (
        <>
            <div className="scheduler-container" style={{ backgroundColor: '#fff', marginTop: '0px' }}>
                {
                    userAdmin && <div style={{ margin: '10px' }}>
                        <Select
                            menuPlacement="auto"
                            styles={customStyles}
                            options={employees}
                            placeholder='Funcionario'
                            value={employeeSelected}
                            onChange={(e) => changeSelectedEmployee(e)}
                        />
                    </div>
                }
                <div className="d-flex justify-content-center" style={{ marginBottom: '20px' }}>
                    <ButtonGroup aria-label="Basic example">
                        <Button variant={viewType === 'day' ? 'secondary' : 'outline-secondary'}
                            onClick={() => { changeType(Views.DAY); }}>
                            Dia
                        </Button>
                        <Button variant={viewType === 'week' ? 'secondary' : 'outline-secondary'}
                            onClick={() => { changeType(Views.WEEK) }}>
                            Semana</Button>
                        <Button variant={viewType === 'month' ? 'secondary' : 'outline-secondary'}
                            onClick={() => { changeType(Views.MONTH) }}>
                            Mês</Button>
                    </ButtonGroup>
                </div>
                <div className="scheduler-calendar">
                    {
                        viewType === 'day' ?
                            <Calendar
                                messages={defaultMessages}
                                formats={{
                                    agendaDateFormat: "DD/MM ddd",
                                    weekdayFormat: "ddd"
                                }}
                                localizer={localizer}
                                onNavigate={handleNavigate}
                                events={events}
                                startAccessor={(event) => event?.start || new Date()} // Prevenção de erro
                                endAccessor={(event) => event?.end || new Date()}     // Prevenção de erro
                                defaultView={Views.DAY}
                                views={[Views.DAY]}
                                eventPropGetter={eventPropGetter}
                                titleAccessor={(event) => `${event.title} - ${event.description || ''}`}
                                onSelectEvent={(event) => handleEventClick(event)} // Captura do clique no evento

                            /> : ''
                    }
                </div>

                {
                    viewType === 'month' ?
                        <Calendar
                            messages={defaultMessages}
                            formats={{
                                agendaDateFormat: "DD/MM ddd",
                                weekdayFormat: "ddd"
                            }}
                            localizer={localizer}
                            events={events}
                            startAccessor={(event) => event?.start || new Date()} // Prevenção de erro
                            endAccessor={(event) => event?.end || new Date()}     // Prevenção de erro
                            defaultView={Views.MONTH}
                            onNavigate={handleNavigate}
                            views={[Views.MONTH]} // Lista de visualizações suportadas
                        /> : ''
                }


                <div className="scheduler-calendar">
                    {viewType === 'week' && (
                        <HallAppShedulerWeek
                            events={events}
                            defaultMessages={defaultMessages}
                            localizer={localizer}
                            navigate={handleNavigate}
                        />
                    )}
                </div>

            </div>
            <FloatingButton icon={faAdd} onClick={() => {
                setShowModalScheduling(true)
            }} />


            {
                showModalScheduling && <ModalScheduling
                    show={showModalScheduling}
                    eventSelected={eventSelected}
                    employees={employees}
                    currentEmployee={employeeSelected}
                    call={() => {
                        setFilter({
                            ...filter,
                        })
                    }}
                    closeShow={() => {
                        setShowModalScheduling(false);
                        if (eventSelected) {
                            setEventSelected(null)
                        }
                    }} />
            }

        </>

    );
}

export default SchedulerCustom;