import '../Services.scss'

import * as formik from 'formik';
import * as yup from 'yup';

import { Button, ButtonGroup } from "react-bootstrap";
import { useEffect, useState } from "react";

import AppCurrencyInput from "../../../commons/inputs/AppCurrencyInput";
import AppInput from "../../../commons/inputs/AppInput";
import Form from 'react-bootstrap/Form';
import noImg from '../../../assets/images/no-img.png'

function AddService({ nextStep, backStep, category, serviceToEdit }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
        value: '',
        duration: ''
    });

    const [value, setValue] = useState(0);
    const [imgLogo, setImgLogo] = useState();

    useEffect(() => {
        if (serviceToEdit != null && Object.keys(serviceToEdit).length > 0) {
            setInitialValues({
                name: serviceToEdit.name,
                duration: serviceToEdit.duration,
                value: serviceToEdit.value
            })
        }
    }, [serviceToEdit]);


    const { Formik } = formik;

    const schema = yup.object().shape({
        name: yup.string().required('Nome do serviço é obrigatório'),
        duration: yup.string().required('Duração do serviço é obrigatório'),
    });

    function createOrUpdate(values) {
        const data = {
            ...values,
            value: value || serviceToEdit?.value,
            categoryId: category.value,
            img: imgLogo
        }
        if (nextStep) {
            nextStep(data)
        }
    }

    function handleChangeImgLogo(e) {
        setImgLogo(e.target.files[0]);
    }

    const handleChangeValue = (event, value, maskedValue) => {
        event.preventDefault();
        setValue(value)
    };

    function convertePorExtenso(minutos) {
        if (minutos < 0) return "Valor inválido";
    
        const horas = Math.floor(minutos / 60);
        const minutosRestantes = minutos % 60;
    
        let resultado = "";
    
        if (horas > 0) {
            resultado += horas === 1 ? "1 hora" : `${horas} horas`;
        }
    
        if (minutosRestantes > 0) {
            if (resultado) resultado += " e ";
            resultado += minutosRestantes === 1 ? "1 minuto" : `${minutosRestantes} minutos`;
        }
    
        return resultado || "0 minutos";
    }

    return (
        <>
            <div style={{ textAlign: "center", marginBottom: '10px' }}>
                <span>
                    O serviço será criado para categoria:
                    <span style={{ fontWeight: "bold" }}> {category?.label}</span></span>
            </div>
            <Formik
                validationSchema={schema}
                onSubmit={createOrUpdate}
                enableReinitialize={true}
                initialValues={initialValues}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <div className='container'>
                        <Form noValidate onSubmit={handleSubmit}>

                            <div className="category-pic" >
                                <img src={(imgLogo && URL.createObjectURL(imgLogo)) || (serviceToEdit?.logo || noImg)} alt="logotipo" />
                            </div >
                            <div style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}>
                                <label
                                    htmlFor="file-upload-logo"
                                    style={{
                                        display: 'inline-block',
                                        padding: '10px 20px',
                                        backgroundColor: '#198754',
                                        color: '#fff',
                                        borderRadius: '15px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        marginTop: '20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <span style={{
                                        color: '#fff', textAlign: 'center', justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>ADICIONAR IMAGEM</span>
                                </label>
                            </div>
                            <input
                                id="file-upload-logo"
                                type="file"
                                onChange={handleChangeImgLogo}
                                style={{ display: 'none' }}
                            />

                            <AppInput
                                errors={errors}
                                touched={touched}
                                name='name'
                                onChange={handleChange}
                                value={values.name}
                                type='text'
                                label='Nome do serviço' />

                            <AppInput
                                errors={errors}
                                touched={touched}
                                name='duration'
                                onChange={handleChange}
                                value={values.duration}
                                formText={convertePorExtenso(values.duration)}
                                type='number'
                                label='Duração - MEDIA EM MINUTOS' />


                            <AppCurrencyInput
                                errors={errors}
                                touched={touched}
                                name='value'
                                onChange={handleChangeValue}
                                value={values.value}
                                label='Valor - A PARTIR DE:'
                            />

                            <div style={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                border: 0,
                                borderRadius: 0,
                                width: '100%',
                            }}>

                                <ButtonGroup style={{ borderRadius: '0', width: '100%', }}>
                                    <Button onClick={backStep} variant="outline-dark" size="lg" style={{ borderRadius: '0', width: '100%', }}>
                                        VOLTAR
                                    </Button>
                                    <Button className="btn-next-category" type="submit" variant="dark" size="lg" style={{ borderRadius: '0', width: '100%', color: '#fff' }}>
                                        <span style={{ color: '#fff' }}>PRÓXIMO</span>
                                    </Button>
                                </ButtonGroup>

                            </div>


                        </Form>
                    </div>
                )}
            </Formik>

        </>
    );
}

export default AddService;