import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import ConfirmModal from '../../../commons/modal/ConfirmModal';
import HallAppToast from '../../../commons/HallAppToast';
import SignatureAPI from '../SignatureAPI';
import { formatDate } from '../../../commons/util/HallAppUtil';
import { useState } from 'react';

const signatureAPI = new SignatureAPI();

function ContractInfoAlert({ contract, callContract, openModalSubscription }) {
    const [showModalCancel, setShowModalCancel] = useState(false)
    const [isLoadingModalCancel, setIsLoadingModalCancel] = useState(false)

    async function confirm() {
        try {
            setIsLoadingModalCancel(true)
            await signatureAPI.cancelContract(contract?.contractId);    
            HallAppToast.success();    
            if(callContract) {
                callContract()
            }   
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setIsLoadingModalCancel(false)
        }
    }

    function getVariant() {
        if (contract?.contractStatus === 'ACTIVE') return 'success';
        if (contract?.contractStatus === 'INACTIVE') return 'danger';
        if (contract?.contractStatus === 'PENDING') return 'warning';
    }

    function getStatus() {
        if (contract?.contractStatus === 'ACTIVE') return 'Ativo';
        if (contract?.contractStatus === 'INACTIVE') return 'Inativo';
        if (contract?.contractStatus === 'PENDING') return 'Aguardando confirmação do pagamento';
        if (contract?.contractStatus === 'IN_CANCELLATION') return 'Em processo de cancelamento';

    }

    return (
        <>
            <Alert variant={getVariant()}>
                <span style={{ fontWeight: 'bold' }}>Data da contratação:</span> {formatDate(contract?.contractDate)}<br></br>
                <span style={{ fontWeight: 'bold' }}>Status:</span> {getStatus()}<br></br>
                <span style={{ fontWeight: 'bold' }}>Plano:</span>  {contract?.planType === 'MONTHLY' ? 'Mensal' : 'Anual'}<br></br>
                <span style={{ fontWeight: 'bold' }}>Forma de pagamento:</span>  {contract?.paymentType === 'CREDIT_CARD' ? 'Cartão de crédito' : 'Boleto'}<br></br>
                <span style={{ fontWeight: 'bold' }}>Cartão final:</span>  {contract?.creditCardFinalNumber}<br></br>

            </Alert>
            <div style={{textAlign: 'center'}}>
                {
                    contract?.contractStatus === 'ACTIVE' ? <><Button style={{ textDecoration: "none" }} variant="link" onClick={() => setShowModalCancel(true)}>Cancelar assinatura</Button> |</>:''
                }
                <Button onClick={() => openModalSubscription()} style={{ textDecoration: "none" }} variant="link">Alterar assinatura</Button>
            </div>

            <ConfirmModal show={showModalCancel}
                title='Cancelar assinatura'
                isLoading={isLoadingModalCancel}
                confirm={confirm}
                closeModal={() => setShowModalCancel(false)}
            >
               Dejesa cancelar sua assinatura?
            </ConfirmModal>

        </>
    );
}

export default ContractInfoAlert;