import ApiService from "../../commons/services/ApiService";
import CompanyAPI from "../company/CompanyAPI";

const apiService = new ApiService();

export default class SchedulingAPI {

    URI_V1 = "/v1/scheduling"

    advancedSearch = async (filter: any) => {
        var query = `companyId=${new CompanyAPI().getCompanyManagment().id}&viewType=${filter.viewType.toUpperCase()}`;

        if (filter.startDate) {
            query += `&startDate=${filter.startDate}`
        }

        if (filter.endDate) {
            query += `&endDate=${filter.endDate}`
        }

        if (filter.employeeId) {
            query += `&employeeId=${filter.employeeId}`
        }

        return await apiService.get(`${this.URI_V1}/advancedSearch?${query}`);
    };

    create = async (data: any) => {
        return await apiService.post(`${this.URI_V1}/employee-created`, data);
    };

    update = async (id: any, data: any) => {
        return await apiService.put(`${this.URI_V1}/${id}/employee`, data);
    };

    canceledScheduling = async (id: any, employeeId: any) => {
        return await apiService.delete(`${this.URI_V1}/${id}?employeeId=${employeeId}`);
    };
}
