import React, { useState } from "react";

import AppButton from "../../commons/buttons/AppButton";
import AppLinkButton from "../../commons/buttons/AppLinkButton";
import CustomCard from "../../commons/cards/Card";
import SubscriptionModal from "./SubscriptionModal";

function SignatureCard() {
    const [showModalCredit, setShowModalCredit] = useState(false)

    const handleWhatsApp = () => {
        window.open("https://wa.me/19981041860", "_blank");
    };

    return (
        <>

                <CustomCard>
                    <p style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>🔥 Transforme a Gestão do Seu Salão com o Hallapp Premium!</p>
                    <p style={{ fontSize: '16px', textAlign: 'center' }}>Acesso exclusivo a funcionalidades avançadas:</p>

                    <p style={{ fontSize: '16px', textAlign: 'center' }}>🔑 Comandas ilimitadas</p>
                    <p style={{ fontSize: '16px', textAlign: 'center' }}>📊 Relatórios detalhados da empresa</p>
                    <p style={{ fontSize: '16px', textAlign: 'center' }}>📈 Relatório individual por funcionário</p>
                    <p style={{ fontSize: '16px', textAlign: 'center' }}>📅 Agenda online para agendamentos rápidos</p>

                    <p style={{ fontSize: '16px', textAlign: 'center' }}>
                        Assine agora e leve sua gestão para o próximo nível!
                    </p>

                    <h4 style={{textAlign: 'center'}}>R$ 69,90</h4>
                    <h6 style={{textAlign: 'center'}}>por mês sem fidelização, cancele a qualquer momento.</h6>

                 <AppButton onClick={() =>  setShowModalCredit(true)} name="assinar" width="100%"/>

                 <div style={{marginTop: '10px'}}>
                    <AppLinkButton onClick={handleWhatsApp} name="falar com atendente" width="100%"/>
                 </div>


                </CustomCard>
            {showModalCredit && <SubscriptionModal show={setShowModalCredit} closeShow={() => setShowModalCredit(false)}/>}

        </>
    );
}

export default SignatureCard;