import * as formik from 'formik';
import * as yup from 'yup';

import { Form, Modal } from "react-bootstrap";

import AppButton from "../../../commons/buttons/AppButton";
import AppInput from "../../../commons/inputs/AppInput";
import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import AppLoading from '../../../commons/AppLoading';
import CompanyAPI from '../CompanyAPI';
import HallAppToast from '../../../commons/HallAppToast';
import { useState } from 'react';

const service = new CompanyAPI()

function ModalCode({ show, handleClose, courrentCompanyId }) {
    const [initialValues, setInitialValues] = useState({
        code: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const { Formik } = formik;

    const schema = yup.object().shape({
        code: yup.string().required('Informe o código').max(4, 'Código deve ter no maximo 4 cracteres')
    });

    const create = async (values) => {
        try {
            setIsLoading(true)
            await service.addCode(courrentCompanyId, values.code)
        } catch (_) {
            HallAppToast.error("Código inválido ou expirado.");
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar código</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={create}
                        initialValues={initialValues}
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <AppInput errors={errors}
                                    touched={touched}
                                    name='code'
                                    onChange={handleChange}
                                    value={values.code}
                                    type='text'
                                    label='Código' />

                                {
                                    isLoading ? <div className='d-flex justify-content-center'>
                                        <AppLoading />
                                    </div> : <div >
                                        <div style={{ marginBottom: '10px' }}>
                                            <AppButton type="submit" width='100%' name='adicionar' />
                                        </div>
                                        <AppLinkButton width='100%' name="fechar" onClick={handleClose} />
                                    </div>
                                }

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default ModalCode;