// import "react-color-palette/css";

import './SchedulerLayout.scss';

import * as formik from 'formik';
import * as yup from 'yup';

import { useEffect, useState } from 'react';

import AppButton from '../../../commons/buttons/AppButton';
import AppInput from '../../../commons/inputs/AppInput';
import AppLinkButton from '../../../commons/buttons/AppLinkButton';
import AppLoading from '../../../commons/AppLoading';
import CompanyAPI from '../../company/CompanyAPI';
import { Form } from 'react-bootstrap';
import HallAppCenterContainer from '../../../commons/containers/HallAppCenterContainer';
import HallAppConst from '../../../commons/util/HallAppConst';
import HallAppToast from '../../../commons/HallAppToast';
import ModalSchedulerLayoutColor from '../components/ModalShedulerLayoutColor';
import SchedulerConfigAPI from '../SchedulerConfigAPI';
import { compressImage } from '../../../commons/util/HallAppUtil';
import noImg from '../../../assets/images/no-img.png'

function SchedulerLayoutConfig() {

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const [backgroundType, setBackgroundType] = useState("COLOR");
    const [showModalColor, setShowModalColor] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#D8BFD8");
    const [imgBackground, setImgBackground] = useState();
    const [imgLogo, setImgLogo] = useState();
    const [layout, setLayout] = useState();
    const [principalColor, setPrincipalColor] = useState("#D8BFD8");
    const [showModalPrincipalColor, setShowModalPrincipalColor] = useState(false);

    const schedulerAPI = new SchedulerConfigAPI();
    const companyAPI = new CompanyAPI();

    const [initialValues, setInitialValues] = useState({
        nickname: '',
        principalColor: '',
    });

    const { Formik } = formik;

    const schema = yup.object().shape({
        nickname: yup.string().required('Nome é obrigatório').max(50, 'Nome deve ter no maximo 50 cracteres'),
        principalColor: yup.string()
    });

    const save = async (values, { setErrors }) => {
        const data = {
            ...values,
            principalColor: principalColor,
            backgroundType: backgroundType,
            imgBackground: backgroundType === 'COLOR' ? null : imgBackground,
            imgLogo: imgLogo,
            companyId: companyAPI.getCompanyManagment().id,
            backgroundColor: backgroundColor
        }
        const formData = new FormData();
        formData.append("dto", new Blob([JSON.stringify(data)], { type: "application/json" }));
        setIsLoadingButton(true)

        if (imgBackground !== null && imgBackground !== undefined) {
            const backgroundCompress = await compressImage(imgBackground);
            formData.append("imgBackground", backgroundCompress);
        }

        if (imgLogo !== null && imgLogo !== undefined) {
            const logoCompress = await compressImage(imgLogo);
            formData.append("imgLogo", logoCompress);
        }

        try {
            if (layout !== null && layout.id) {
                await schedulerAPI.updateLayout(layout.id, formData)
            } else {
                await schedulerAPI.createLayout(formData);
                await getLayout();
            }
            HallAppToast.success();
        } catch (error) {
            const response = error.response;
            if (response.status === 400) {
                HallAppToast.error(HallAppConst.ERROR_IN_FORM);
                if (response.data[0].code === 'NICKNAME_ALREADY_EXISTS') {
                    setErrors({ nickname: 'O nome informado já existe.' });
                }
            } else {
                HallAppToast.error(HallAppConst.GENERIC_ERROR);
            }
        } finally {
            setIsLoadingButton(false)
        }
    }

    async function getLayout() {
        try {
            setIsLoading(true)
            const resp = await schedulerAPI.getLayout()
            setLayout(resp.data)
            populateDataLayout(resp.data);
        } catch (_) {
            setLayout(null)
        } finally {
            setIsLoading(false)
        }
    };

    function populateDataLayout(data) {
        setBackgroundType(data.layoutBackgroundType)

        if (data.layoutBackgroundType === 'COLOR') {
            setBackgroundColor(data.value)
        }

        setInitialValues({
            nickname: data.nickname,
            principalColor: data.principalColor
        })

        setPrincipalColor(data.principalColor)
    }


    useEffect(() => {
        getLayout()
    }, []);

    function changeColor(value) {
        setBackgroundType('COLOR')
        setBackgroundColor(value);
    }

    function handleChangeImgBackground(e) {
        setBackgroundType('IMG')
        setImgBackground(e.target.files[0]);
    }

    function handleChangeImgLogo(e) {
        setImgLogo(e.target.files[0]);
    }

    return (
        <>
            {
                isLoading ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : <>
                    <div className='container'>
                        <div className="top-section" style={{
                            backgroundColor: backgroundColor,
                            backgroundImage: backgroundType === 'COLOR' ? '' : `url(${imgBackground && URL.createObjectURL(imgBackground) || layout?.value})`,
                            backgroundSize: '100% 220px',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '220px'
                        }}>
                            <label style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                backgroundColor: '#198754',
                                color: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                textAlign: 'center',
                                marginTop: '10px',
                                width: '210px'
                            }}
                                onClick={() => {
                                    setShowModalColor(true);
                                }}>
                                <span style={{ color: '#fff' }}>ADICIONAR COR</span>
                            </label>
                            <br></br>
                            <label
                                htmlFor="file-upload"
                                style={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    backgroundColor: '#198754',
                                    color: '#fff',
                                    borderRadius: '15px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    marginTop: '13px',
                                    width: '210px'
                                }}
                            >
                                <span style={{ color: '#fff' }}>ADICIONAR IMAGEM</span>
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleChangeImgBackground}
                                style={{ display: 'none' }}
                            />
                            <div className="profile-pic" >
                                <img
                                    src={
                                        (imgLogo && URL.createObjectURL(imgLogo)) ||
                                        (layout?.logo ? `${layout.logo}?t=${new Date().getTime()}` : noImg)
                                    }
                                    alt="logotipo"
                                />
                            </div >
                        </div >

                        <div className="bottom-section">
                            <div style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}>
                                <label
                                    htmlFor="file-upload-logo"
                                    style={{
                                        display: 'inline-block',
                                        padding: '10px 20px',
                                        backgroundColor: '#198754',
                                        color: '#fff',
                                        borderRadius: '15px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '210px'
                                    }}
                                >
                                    <span style={{
                                        color: '#fff', textAlign: 'center', justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>ADICIONAR LOGO</span>
                                </label>
                            </div>
                            <input
                                id="file-upload-logo"
                                type="file"
                                onChange={handleChangeImgLogo}
                                style={{ display: 'none' }}
                            />

                            <div style={{ marginTop: '30px' }}>
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={save}
                                    initialValues={initialValues}
                                    enableReinitialize={true}
                                >
                                    {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                        <Form noValidate onSubmit={handleSubmit}>

                                            <div className='container'>
                                                <AppInput errors={errors}
                                                    touched={touched}
                                                    readOnly={true}
                                                    name='principalColor'
                                                    onChange={handleChange}
                                                    backgroundColor={principalColor}
                                                    onClick={() => setShowModalPrincipalColor(true)}
                                                    formText={`Usada para colocar nos botões.`}
                                                    type='text'
                                                    label='Cor Principal' />

                                                <AppInput errors={errors}
                                                    touched={touched}
                                                    name='nickname'
                                                    onChange={handleChange}
                                                    value={values.nickname}
                                                    formText={`Usado para encontrar o estabelecimento \n https://agendamento.hallapp.com.br/${values.nickname || ''}`}
                                                    type='text'
                                                    label='Nome' />
                                            </div>

                                            {
                                                isLoadingButton ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : <AppButton width='100%' name='salvar' type='submit' />
                                            }

                                            {
                                                layout?.id && <div style={{ marginTop: '10px' }}>
                                                    <AppLinkButton width='100%' name='compartilhar link' onClick={() => {
                                                        const urlToShare = `${HallAppConst.AGENDAMENTO_URL}/${values.nickname}`; // Substitua pela URL que deseja compartilhar
                                                        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(urlToShare)}`;
                                                        window.open(whatsappUrl, "_blank");
                                                    }} />
                                                </div>
                                            }

                                        </Form>

                                    )}
                                </Formik>
                            </div>
                        </div>

                    </div>
                    <ModalSchedulerLayoutColor
                        setBackgroundColor={changeColor}
                        show={showModalColor}
                        closeModal={() => setShowModalColor(false)} />

                    <ModalSchedulerLayoutColor
                        setBackgroundColor={(color) => setPrincipalColor(color)}
                        show={showModalPrincipalColor}
                        closeModal={() => setShowModalPrincipalColor(false)} />
                </>
            }

        </>

    );
}

export default SchedulerLayoutConfig;