import * as formik from 'formik';
import * as yup from 'yup';

import { Alert, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

import AppButton from '../../../../commons/buttons/AppButton';
import AppInput from '../../../../commons/inputs/AppInput';
import AppLinkButton from '../../../../commons/buttons/AppLinkButton';
import AppLoading from '../../../../commons/AppLoading';
import CalendarInput from '../../../../commons/inputs/CalendarInput';
import CompanyAPI from '../../../company/CompanyAPI';
import { Exception } from 'sass';
import { Form } from 'react-bootstrap';
import HallAppCenterContainer from '../../../../commons/containers/HallAppCenterContainer';
import HallAppToast from '../../../../commons/HallAppToast';
import SchedulingAPI from '../../SchedulingAPI';
import Select from 'react-select'
import ServiceAPI from '../../../services/ServiceAPI';
import UserService from '../../../../auth/UserService';
import dayjs from "dayjs";
import { formatDateInEN } from '../../../../commons/util/HallAppUtil';
import { useMask } from '@react-input/mask';

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: 60,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

const serviceAPI = new ServiceAPI();
const companyAPI = new CompanyAPI();
const schedulingAPI = new SchedulingAPI();
const userService = new UserService();

function ModalScheduling({ closeShow, show, call, eventSelected, employees = [], currentEmployee }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
        whatsapp: '',
    });
    const [isLoading, setLoading] = useState(false);
    const [services, setServices] = useState([])
    const [serviceSelected, setServiceSelected] = useState(null)
    const [serviceDate, setServiceDate] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [optionSelected, setOptionSelected] = useState(null)
    const [employeeSelected, setEmployeeSelected] = useState(null);

    useEffect(() => {
        findAllServices();
        if (currentEmployee) {
            setEmployeeSelected(currentEmployee)
        }
    }, []);

    function populateFormEdit(options) {
        setServiceDate(eventSelected.schedulingDate)
        setStartDate(dayjs(eventSelected.schedulingDate).toDate())

        setInitialValues({
            name: eventSelected.customerName,
            hours: eventSelected.startTime,
            whatsapp: formatPhoneNumber(eventSelected.phone)
        })
        const service = options.find((o) => o.value === eventSelected.serviceId)
        setServiceSelected(service.value)
        setOptionSelected(service);
    }

    const findAllServices = async () => {
        try {
            setLoading(true)
            const response = await serviceAPI.findAllByCompanyId(companyAPI.getCompanyManagment().id)
            if (response.data) {
                response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
                const options = response.data.map(d => ({ value: d.id, label: d.name }));
                setServices(options);
                if (eventSelected) {
                    populateFormEdit(options)
                }
            }
            setLoading(false)
        } catch (_) {
            setLoading(false)
        }
    };

    const { Formik } = formik;


    const phoneValidation = (value) => {
        const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
        return phoneRegex.test(value);
    };

    const nameValidation = (value) => {
        const nameParts = value.trim().split(' ');
        return nameParts.length >= 2;
    };

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Regex para validar formato de hora (HH:mm)

    const schema = yup.object().shape({
        name: yup.string().required('Nome é obrigatório')
            .test('name-format', 'Por favor, informe o nome completo (nome e sobrenome)', nameValidation),
        whatsapp: yup.string().required('Telefone é obrigatório')
            .test('phone-format', 'Telefone deve ter exatamente 10 dígitos numéricos', phoneValidation),
        hours: yup.string().required('Horário é obrigatório').matches(timeRegex, 'Horário inválido')
    });

    function handleChangeService(selected) {
        if (selected) {
            setServiceSelected(selected.value);
            setOptionSelected(selected)
        }
    }

    function handleChangeDate(date) {
        setServiceDate(date)
    }

    function formatPhoneNumber(phone) {
        if (!phone || phone.length !== 11) {
            return "Número inválido";
        }

        return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
    }

    const changeSelectedEmployee = (employee) => {
        setEmployeeSelected(employee);
    };


    const canceledEvent = async () => {
        setLoading(true)
        try {
            await schedulingAPI.canceledScheduling(eventSelected.id, userService.getUserData().id);
            HallAppToast.success();
            //usado para o componente pai atualizar
            if (call) {
                call()
                closeShow()
            }
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setLoading(false)
        }
    }

    const create = async (values, { resetForm }) => {
        if (serviceSelected === null || serviceSelected === undefined) {
            HallAppToast.error("Selecione o serviço.");
            throw new Error("selecione um serviço")
        }
        const data = {
            serviceId: serviceSelected,
            date: serviceDate || formatDateInEN(new Date()),
            companyId: companyAPI.getCompanyManagment().id,
            name: values.name,
            time: values.hours,
            phone: values.whatsapp,
            employeeId: currentEmployee?.value
        }

        setLoading(true)

        try {
            if (eventSelected) {
                await schedulingAPI.update(eventSelected.id, data);
            } else {
                await schedulingAPI.create(data);
                resetForm();
            }

            HallAppToast.success();
            //usado para o componente pai atualizar
            if (call) {
                call()
                closeShow()
            }
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setLoading(false)
        }
    }
    const phoneMask = useMask({ mask: '(__) _____-____', replacement: { _: /\d/ } });
    const hoursMask = useMask({ mask: '__:__', replacement: { _: /\d/ } });

    return (
        <>
            <Modal show={show} fullscreen={false} centered={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        AGENDAMENTO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={create}
                        initialValues={initialValues}
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="commando-form-service-date" style={{ marginBottom: '20px' }}>
                                    <CalendarInput
                                        selectionMode="single"
                                        setDates={setStartDate}
                                        changeDates={handleChangeDate}
                                        values={startDate} />
                                </div>

                                {
                                    employees.length > 0 && <div style={{ marginBottom: '15px' }}>
                                        <Select
                                            menuPlacement="auto"
                                            styles={customStyles}
                                            options={employees}
                                            placeholder='Funcionario'
                                            value={employeeSelected}
                                            onChange={(e) => changeSelectedEmployee(e)}
                                        />
                                    </div>
                                }

                                <div style={{ marginBottom: '20px' }}>
                                    <Select
                                        styles={customStyles}
                                        options={services}
                                        value={optionSelected}
                                        onChange={handleChangeService}
                                        isClearable
                                        isSearchable
                                        placeholder='Selecione o serviço'
                                    />
                                </div>

                                <AppInput errors={errors}
                                    touched={touched}
                                    name='hours'
                                    onChange={handleChange}
                                    value={values.hours}
                                    mask={hoursMask}
                                    type='text'
                                    label='Horário' />

                                <AppInput errors={errors}
                                    touched={touched}
                                    name='name'
                                    onChange={handleChange}
                                    value={values.name}
                                    type='text'
                                    label='Nome do(a) Cliente' />

                                <AppInput errors={errors}
                                    touched={touched}
                                    name='whatsapp'
                                    onChange={handleChange}
                                    value={values.whatsapp}
                                    mask={phoneMask}
                                    type='text'
                                    label='Whatsapp' />
                                {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                    <div>
                                        <AppButton type="submit" width='100%' name='adicionar' />
                                        {
                                            eventSelected && <div style={{ marginTop: '10px' }}>
                                                <AppLinkButton onClick={() => { canceledEvent() }} width='100%' name='cancelar agendamento' />
                                            </div>
                                        }
                                    </div>
                                }
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalScheduling;