import "./SchedulerWeek.scss";

import { useEffect, useState } from "react";

import { Calendar } from "react-big-calendar";

const HallAppShedulerWeek = ({ events = [], defaultMessages, localizer, navigate }) => {
    const [date, setDate] = useState(null);

    const handleNavigate = (value) => {
        setTimeout(() => {
            navigate(value, 'week')
            if(formatDateWithoutTime(value) == date) return;
            setDate(formatDateWithoutTime(value))
            setHeaderWeekDay(); // Chamar a função após a atualização do DOM
        }, 0);
    };

    function formatDateWithoutTime(dateString) {
        // Converte a string para um objeto Date
        const date = new Date(dateString);

        // Extrai o ano, mês (ajustando para começar em 1) e o dia
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Retorna no formato desejado (DD/MM/AAAA ou AAAA-MM-DD)
        return `${day}/${month}/${year}`; // Formato DD/MM/AAAA
        // return `${year}-${month}-${day}`; // Formato AAAA-MM-DD
    }

    function getFirstText(input) {
        const match = input?.match(/^[^\s0-9–\-]+/);
        return match ? match[0] : null;
    }

    function generateWeekDays(month, day) {
        // Array com os meses do ano (em ordem)
        const months = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
        // Dias da semana em português
        const weekdays = ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"];

        // Verifica o índice do mês na lista
        const monthIndex = months.indexOf(month.toLowerCase());
        if (monthIndex === -1) {
            throw new Error("Mês inválido");
        }

        // Constrói a data inicial
        const startDate = new Date(new Date().getFullYear(), monthIndex, day);

        const result = [];
        for (let i = 0; i < 7; i++) {
            // Calcula a data do dia atual
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);

            // Extrai informações do dia atual
            const currentDay = currentDate.getDate();
            const currentWeekday = weekdays[currentDate.getDay()];
            const currentMonth = months[currentDate.getMonth()];

            const data = {
                day: currentDay,
                weekName: currentWeekday.slice(0, 3)?.toLowerCase(),
                month: currentMonth.slice(0, 3)?.toLowerCase(),
            }

            result.push(data);
        }

        return result;
    }

    useEffect(() => {
        setHeaderWeekDay();
    }, []);

    function setHeaderWeekDay() {
        const daySlots = document.querySelectorAll('.rbc-day-slot');
        const spanValue = document.querySelector('.rbc-toolbar-label')?.textContent;

        var startDay = 0;
        if (spanValue) {
            const matches = spanValue.match(/\d+/g); // Captura todos os números na string
            if (matches && matches.length >= 2) {
                const [start] = matches; // Pega os dois primeiros números
                startDay = start
            }
        }

        const result = generateWeekDays(getFirstText(spanValue), parseInt(startDay));

        daySlots.forEach((daySlot, index) => {
            // Criar o cabeçalho personalizado
            const headerDiv = document.createElement('div');
            headerDiv.className = 'custom-day-header';

            // Limpa o conteúdo anterior do headerDiv
            headerDiv.innerHTML = '';

            // Cria os elementos para cada valor
            const dayDiv = document.createElement('div');
            dayDiv.innerText = result[index].day;
            // Adiciona uma classe
            dayDiv.className = 'day-class';

            const weekNameDiv = document.createElement('div');
            weekNameDiv.innerText = result[index].weekName;
            // Adiciona uma classe
            weekNameDiv.className = 'week-name-class';

            const monthDiv = document.createElement('div');
            monthDiv.innerText = result[index].month;
            // Adiciona uma classe
            monthDiv.className = 'month-class';

            // Adiciona os elementos ao headerDiv
            headerDiv.appendChild(monthDiv);
            headerDiv.appendChild(dayDiv);
            headerDiv.appendChild(weekNameDiv);

            // Ajustar o layout do dia para comportar o cabeçalho no topo
            daySlot.style.position = 'relative';
            daySlot.style.display = 'flex';
            daySlot.style.flexDirection = 'column';

            // Inserir o cabeçalho como o primeiro elemento dentro da coluna
            daySlot.prepend(headerDiv);

        });
    }

    return (
        <Calendar
            className="hallapp-scheduler-week"
            messages={defaultMessages}
            formats={{
                agendaDateFormat: "DD/MM ddd",
                weekdayFormat: "ddd"
            }}
            localizer={localizer}
            events={events}
            titleAccessor={(event) => `${event.title} - ${event.description || ''}`}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            onNavigate={handleNavigate}
            views={["week"]}
        />
    );
};

export default HallAppShedulerWeek;