import './Template.scss'

import { useLocation, useNavigate } from 'react-router-dom';

import { Nav } from 'react-bootstrap';
import React from 'react';

interface StepProps {
    icon: String;
    label: String;
    path: String;
}

const SidebarItem: React.FC<StepProps> = ({  label, path, handleClose }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const redirect = () => {
        if (path === 'suporte') {
            window.open("https://wa.me/19981041860", "_blank");
        } else {
            navigate(path);
            handleClose()
        }

    };

    function getCurrentPath() {
        return location.pathname.replace("/", "")
    }

    return (
        <div style={{backgroundColor: getCurrentPath() === path ? '#d4d2ff':  ''}}>
            <Nav.Item>
                <Nav.Link onClick={() => redirect()} className="nav-link">
                    <i style={{color: 'black'}} className="bi bi-grid-fill sidebar-item-text"></i> 
                    <span className="sidebar-item-text">{label}</span>
                </Nav.Link>
            </Nav.Item>
        </div>

    );
}

export default SidebarItem;