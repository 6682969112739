import React, { useEffect, useState } from "react";

import Alert from 'react-bootstrap/Alert';
import AppLoading from "../../commons/AppLoading";
import ContractInfoAlert from "./components/ContractInfoAlert";
import HallAppCenterContainer from "../../commons/containers/HallAppCenterContainer";
import SignatureAPI from "./SignatureAPI";
import SignatureCard from "./SignatureCard";
import SubscriptionModal from "./SubscriptionModal";

const signatureAPI = new SignatureAPI();

function Signature() {
    const [isLoading, setLoading] = useState(true);
    const [contract, setContract] = useState(null);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        getContract();
    }, []);

    async function getContract() {
        try {
            setLoading(true)
            const resp = await signatureAPI.getContract();
            if (resp.data) {
                setContract(resp.data)
            } else {
                setContract(null)
            }
        } catch (_) {
            setContract(null)
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            <div className="container">
                <h1>Planos e Assinaturas</h1>

                {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                    contract ?
                        <ContractInfoAlert callContract={getContract}
                         contract={contract}
                         openModalSubscription={() => {
                            setShowModal(true)
                         }} /> : <SignatureCard />
                }
            </div>
            {showModal && <SubscriptionModal show={showModal} closeShow={() => setShowModal(false)}/>}

        </>
    );
}

export default Signature;