import * as formik from 'formik';
import * as yup from 'yup';

import { Form, Modal } from "react-bootstrap";
import { faCreditCard, faQrcode } from '@fortawesome/free-solid-svg-icons';

import Alert from 'react-bootstrap/Alert';
import AppButton from '../../commons/buttons/AppButton';
import AppInput from '../../commons/inputs/AppInput';
import AppLoading from '../../commons/AppLoading';
import CustomCard from '../../commons/cards/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HallAppCenterContainer from '../../commons/containers/HallAppCenterContainer';
import HallAppToast from '../../commons/HallAppToast';
import SignatureAPI from './SignatureAPI';
import bandeirasIMG from '../../assets/images/bandeiras.png'
import forge from 'node-forge';
import { useMask } from '@react-input/mask';
import { useState } from "react";

const signatureAPI = new SignatureAPI();
function SubscriptionModal({ closeShow, show }) {
    const SECRET_KEY = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmvikhXA2Se/mkf8zaOzg+DXHbP4vHc6t0E6nj6wjWV5cWSXBGkWWqhv5PspPUpuAPI3t0ixRaltYE68DfuI2TaUDvpzZitaQHfha0v1sNB3jAWqGf3Tzq01U+Y+9oi3dWcTOVlMak68hLkTXIiU8ySRayyoZgumiPMAxWWlL7c/sfpYnRTjaJz5pd8S3Cl6+uUcXuyZv284UFcLYXQ62RQ9AhfJnhG3FRotAdwNHbZKJMVzJ+RsZUrYsK9ZdzeK5rje1sUSyhghEKMkScefNz/uXJuLsYgl7hes6bESXueSQ6k0n5fu30dmJZJPDyylN9tD1JpsVHEaPWvL3kKPlmQIDAQAB-----END PUBLIC KEY-----"

    const [isLoading, setLoading] = useState(false);
    const [paymentSelected, setPaymentSelected] = useState('CREDIT_CARD');

    const [initialValues, setInitialValues] = useState({
        number: '',
        name: '',
        dueDate: '',
        cvv: '',
        cpf: ''
    });
    const { Formik } = formik;

    const schema = yup.object().shape({
        number: yup
            .string()
            .matches(
                /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|(606282|3841[0-9]{2})[0-9]{10}|(38|60)[0-9]{11,14})$/,
                'Número de cartão inválido ou bandeira não suportada'
            )
            .required('Informe o número do cartão'),
        name: yup.string().required('Informe o nome impresso no cartão'),
        dueDate: yup.string().required('Informe a data de vencimento'),
        cvv: yup.string().required('Informe o código CVV'),
        cpf: yup.string().required('Informe o CPF do titular'),
    });

    const create = async (values) => {

        const encryptedData = encryptData(values);
        try {
            setLoading(true)
            const data = {
                creditCardData: encryptedData,
                paymentType: 'CREDIT_CARD',
                planType: 'MONTHLY'
            }
            await signatureAPI.subscriptions(data);
            HallAppToast.success();
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setLoading(false)
        }
    }

    const generateKeyPix = async () => {
        try {
            setLoading(true)
         
          
        } catch (_) {
            HallAppToast.genericError();
        } finally {
           // setLoading(false)
        }
    }

    const encryptData = (data) => {
        const rsa = forge.pki.publicKeyFromPem(SECRET_KEY);
        const encrypted = rsa.encrypt(JSON.stringify(data), 'RSA-OAEP');
        return forge.util.encode64(encrypted);
    };

    const dueDateMask = useMask({ mask: '__/____', replacement: { _: /\d/ } });

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Plano e pagamento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Forma de pagamento</h4>

                    <div style={{ marginTop: '30px' }} className='row'>
                        <div className='col-6' onClick={() => setPaymentSelected('CREDIT_CARD')}>
                            <CustomCard backgroundColor={paymentSelected === 'CREDIT_CARD' ? '#4718ad' : ''}>
                                <div style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={faCreditCard} size="3x" color={paymentSelected === 'CREDIT_CARD' ? '#ffff' : 'grey'} />
                                    <h6 style={{ marginTop: '10px', color: paymentSelected === 'CREDIT_CARD' ? '#ffff' : '' }}>Cartão de crédito</h6>
                                </div>
                            </CustomCard>
                        </div>
                        {/* <div className='col' onClick={() => setPaymentSelected('PIX')}>
                            <CustomCard backgroundColor={paymentSelected === 'PIX' ? '#4718ad' : ''}>
                                <div style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={faQrcode} size="3x" color={paymentSelected === 'PIX' ? '#ffff' : 'grey'} />
                                    <h6 style={{ marginTop: '10px', color: paymentSelected === 'PIX' ? '#ffff' : '' }}>PIX</h6>
                                </div>
                            </CustomCard>
                        </div> */}
                    </div>

                    {
                        paymentSelected === 'CREDIT_CARD' && <div>
                            <h6 style={{ marginTop: '30px' }}>Informe os dados para pagamento</h6>

                            <Alert variant="info">
                                A renovação será feita automaticamente com base nos dados do cartão informado, cancele a qualquer momento sem custos.
                            </Alert>

                            <h4 style={{ marginTop: '50px' }}>Cartão de crédito</h4>
                            <img src={bandeirasIMG} style={{ width: '300px' }} alt='logo' />

                            <Formik
                                validationSchema={schema}
                                onSubmit={create}
                                initialValues={initialValues}
                                enableReinitialize={true}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <AppInput errors={errors}
                                            touched={touched}
                                            name='number'
                                            onChange={handleChange}
                                            value={values.number}
                                            type='text'
                                            label='Número' />

                                        <AppInput errors={errors}
                                            touched={touched}
                                            name='name'
                                            onChange={handleChange}
                                            value={values.name}
                                            type='text'
                                            label='Nome do titular' />

                                        <div className='row'>
                                            <div className='col'>
                                                <AppInput errors={errors}
                                                    touched={touched}
                                                    name='dueDate'
                                                    onChange={handleChange}
                                                    value={values.dueDate}
                                                    mask={dueDateMask}
                                                    type='text'
                                                    label='Data de validade' />
                                            </div>
                                            <div className='col'>
                                                <AppInput errors={errors}
                                                    touched={touched}
                                                    name='cvv'
                                                    onChange={handleChange}
                                                    value={values.cvv}
                                                    type='text'
                                                    label='CVV' />
                                            </div>
                                        </div>


                                        <AppInput errors={errors}
                                            touched={touched}
                                            name='cpf'
                                            onChange={handleChange}
                                            value={values.cpf}
                                            type='text'
                                            label='CPF do titular' />


                                        {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                            <AppButton type="submit" width='100%' name='finalizar pagamento' />
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    }

                    {
                        paymentSelected === 'PIX' && <div  style={{ marginTop: '30px' }}>
                            {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                <AppButton type="submit" width='100%' name='gerar chave PIX' onClick={generateKeyPix} />
                            }

                        </div>}

                </Modal.Body>
            </Modal>
        </>
    );
}

export default SubscriptionModal;