import ApiService from "../../commons/services/ApiService";

const apiService = new ApiService();

export default class SignatureAPI {

    URI_V1 = "/v1/subscriptions"

    subscriptions = async (data: any) => {
        return await apiService.post(`${this.URI_V1}`, data);
    };

    getContract = async () => {
        return await apiService.get(`${this.URI_V1}/contract`);
    };

    cancelContract = async (contractId: any) => {
        return await apiService.put(`${this.URI_V1}/${contractId}/cancel`, {});
    };

}
